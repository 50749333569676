import React from 'react';
import { Link } from "gatsby"
import Image from '../components/image';
import { useStaticQuery, graphql } from "gatsby"
import Newsletter from '../components/newsletter'

const Navigation:React.FunctionComponent<{toggleState: any}> = ({toggleState}) => {
  //const { signIn } = useContext(UserContext);

    //const gNaveMenuItems = props.data
    const { allWordpressMenusMenusItems } = useStaticQuery(
      graphql`
      query MyQuery { 
  allWordpressMenusMenusItems(filter: {slug: {eq: "global-navigation"}}) {
    edges {
      node {
        items {
          object_id
          menu_item_parent
          slug
          title
          url
          description
          post_content
          child_items {
            object_id
            menu_item_parent
            url
            title
            description
            post_content
            slug
          }
        }
      }
    }
  }  
      }
      `
    )


    let menuCol1 = [];
    let menuCol2 = [];
    let menuCol3 = '';

    let gNaveMenuItems = allWordpressMenusMenusItems.edges[0].node.items;

    for(let i = 0; i < gNaveMenuItems.length; i++){
      let url:string = gNaveMenuItems[i]['url'];
      url = url.replace('http://wp.yojiroimasaka.com', '');
      
      if(url[url.length - 1] == '/'){
        url = url.slice(0, -1) + '/';
      }
      if(gNaveMenuItems[i]['object_id'] == 103){
        if(gNaveMenuItems[i]['child_items'].length){
          for(let j= 0; j < gNaveMenuItems[i]['child_items'].length; j++){
            url = gNaveMenuItems[i]['child_items'][j]['url'].replace('http://wp.yojiroimasaka.com', '');
            let list = <li key={j}><Link to={url}>{gNaveMenuItems[i]['child_items'][j]['title']}</Link></li>;
            menuCol1.push(list);
          }
        }
      } else if(gNaveMenuItems[i]['object_id'] == 104){
        menuCol3 = gNaveMenuItems[i]['post_content']
      } else if(gNaveMenuItems[i]['object_id'] != 103) {
        let list = <li key={i}><Link to={url}>{gNaveMenuItems[i]['title']}</Link></li>;
        menuCol2.push(list);
      }

    }

    return (
      <nav id="g-nav">
        <span className="go-home-button close-button" onClick={toggleState}><Image src="icon_close_01.svg" alt="close" /></span>
        <div className="cols">
          <div className="col">
            <ul>
              <li>
                <Link to="/">Works</Link>
                <ul>
                  {menuCol1}
                </ul>                
              </li>
            </ul>
          </div>
          <div className="col">       
            <ul>
              {menuCol2}
            </ul>
          </div>
          <div className="col">
            <ul>
              <li>
                Contact
                <ul className="contact-info" dangerouslySetInnerHTML={{ __html: menuCol3 }}>                  
                </ul>
              </li>
            </ul>
            <div className="news-letter">
              <Newsletter />
            </div>            
          </div>
        </div>
      </nav>
    );
};


export default Navigation;



// export const query = graphql`
//       query($slug: String!) { 
//         allWordpressMenusMenusItems(filter: {slug: { eq: $slug }}) {
//           edges {
//             node {
//               items {
//                 slug
//                 title
//                 url
//                 description
//               }
//             }
//           }
//         }      
//       }

//     `;