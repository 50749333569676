import React from 'react';
import { myProps, myState } from '../interface'
import Image from '../components/image';

class Burger extends React.Component<myProps, myState> {

  constructor(props: myProps) {
    super(props);
    this.state = {
      open: false
    }
  }

  toggleBurger(){
    return false;
  }
  
  render (){
    return (
      <div className="toggle-nav-button hamburger-button" aria-label="Toggle menu" data-is-open={this.props.open} onClick={()=>{
        this.props.onUpdate();
      }}><Image src="icon_menu_01.svg" alt="menu" /></div>
    );
  }
}


export default Burger;