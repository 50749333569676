import { Link } from "gatsby"
import React from 'react'
import Burger from '../components/burger'
import Navigation from '../components/navigation'
import Image from '../components/image'
import { myProps, myState } from '../interface'

type Props = {
  toggleChildMenu:any,
	myRef:any
}

class Header extends React.Component<myProps, myState, Props> {
	
	isModalOpen:boolean = false;

	static get defaultProps() {
    return {
      open: false,
    }
  }

  constructor(props: myProps) {
    super(props);
    this.state = {
      open: false
    }
	
		this.toggle = this.toggle.bind(this);
		//this.documentClickHandler = this.documentClickHandler.bind(this);
		
  }

	componentDidMount() {
		let header = document.getElementById("g-nav");
		if(header)
		header.classList.add("ready");

  }

	componentDidUpdate() {
		document.addEventListener('mousedown', this.documentClickHandler);		
  }

	componentWillUnmount(){

	}
		

  lockBackground = () => {
    this.isModalOpen = !this.isModalOpen;
    if (this.isModalOpen) {
      let offsetY = window.pageYOffset;
      document.body.style.top = `${-offsetY}px`;
      document.body.classList.add('no-scroll');
    } else {
      let offsetY:any = document.body.style.top;
      offsetY = Number(offsetY.replace('px', ''));
      offsetY = Math.abs(parseInt(offsetY || 0, 10));
     	document.body.classList.remove('no-scroll');
      document.body.style.top = '0';
      window.scrollTo(0, offsetY);
    }
  }

	toggle(){
		this.setState({
			open: !this.state.open
		}, () => {
  		this.lockBackground();
		});
	}

	render(){
		return (
			<header id="header" data-is-open={this.state.open}>
				<div className="container">
					<p className="logo"><Link to="/"><Image src="logo.svg" alt="YOJIRO IMASAKA"/></Link></p>
					<Burger onUpdate={this.toggle.bind(this)} open={this.state.open} />
					<Navigation toggleState={this.toggle} />
				</div>
			</header>
		);
	}

  documentClickHandler(){

  }

  removeDocumentClickHandler(){    
    document.removeEventListener('click', this.documentClickHandler)
  }
    
}



export default Header;
