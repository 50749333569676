/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import Header from "./header"
import Footer from "./footer"
import "./style.scss"


type Props = {
  title?: string,
  children: any
}


class Layout extends React.Component<Props> {

  title:any;
  children:any;
  data:any;


  constructor(props: Props) {
    super(props);
	
    this.title = props.title ? props.title + ' - ' : '';	
    this.children = props.children; 

  }

	componentDidMount() {
    this.updateBodyClass();
  }

	componentDidUpdate() {
    this.updateBodyClass();
  }

  updateBodyClass() {
    const url = window.location.pathname.split('/');
    const pageName = url[1] ? url[1] : 'home';
    const detailPage = url[2] ? url[1] + '-detail-page' : '';
    document.body.className = '';
    document.body.classList.add(pageName + '-page');

    if(detailPage)
    document.body.classList.add(detailPage);
  }  


  render(){
    return (
      <>
        <Header />

        <main id="trunk">{this.children}</main>
          
        <Footer />
      </>
    )
  }
}

// Layout.propTypes = {
//   children: PropTypes.node.isRequired,
// }

export default Layout;
