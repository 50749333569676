import MailchimpSubscribe from "react-mailchimp-subscribe"
import React, { Component } from "react";

type Props = {
  status?: any
  message?: any
  onValidated?: any,
}

// a basic form
const CustomForm: React.FunctionComponent<Props> = ({ status, message, onValidated }) => {
  let email:any;
  const submit = () =>
    email &&
    email.value.indexOf("@") > -1 &&
    onValidated({
      EMAIL: email.value
    });

  return (
    <div className="input-box">
      <input        
        ref={node => (email = node)}
        type="email"
        placeholder="Enter Email"
      />
      <button onClick={submit}>
        Submit
      </button>
      {status === "sending" && <div>sending...</div>}
      {status === "error" && (
        <div
          className="error"
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      {status === "success" && (
        <div className="success"
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}      
    </div>
  );
};

class Newsletter extends Component {
  render() {
    const url =
      "https://yojiroimasaka.us4.list-manage.com/subscribe/post?u=98afb44a2110b7ccf3fe85e1b&amp;id=b59f1f57ab";
    return (
      <div className="newsletter-box">
        <h2>Newsletter</h2>
        <MailchimpSubscribe
          url={url}
          render={({ subscribe, status, message }) => (
            <CustomForm
              status={status}
              message={message}
              onValidated={(formData:any) => subscribe(formData)}
            />
          )}
        />
      </div>
    );
  }
}

export default Newsletter;